<template>
  <div class="v-lydbok o-wrapper u-px u-py--2">
    <FHHeading header="h1" text="Lesebok lydbok" />
    <div class="v-lydbok__content" v-if="!loading">
      <div class="v-lydbok__text">
        {{ description }}
      </div>

      <div class="v-lydbok__audio-wrapper" v-if="currentAudio">
        <FHAudio
          v-if="currentAudio.file && currentAudio.file.url"
          :fileUrl="currentAudio.file && currentAudio.file.url"
          @init="registerPlayer"
        />
        <div v-if="audioList.length" class="v-lydbok__chapters-wrapper">
          <FHButton v-for="(audio, i) in audioList" class="v-lydbok__button" :class="{'v-lydbok__button--active': i === currentAudioIndex}" :key="audio.id" @click.native="setCurrentAudio(i)">
            {{ i + 1 }}. {{ audio.name }}
          </FHButton>
        </div>
      </div>
    </div>

    <ContentLoader height="75" v-else>
      <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <rect x="0" y="25" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="50" rx="3" ry="3" width="30" height="10" />
      <rect x="35" y="50" rx="3" ry="3" width="30" height="10" />
      <rect x="70" y="50" rx="3" ry="3" width="30" height="10" />
    </ContentLoader>
  </div>
</template>

<script>
import FHHeading from '@forlagshuset/v-heading'
import FHAudio from '@forlagshuset/v-audio-player'
import FHButton from '@forlagshuset/v-button'
import { ContentLoader } from 'vue-content-loader'
import { ref, onMounted } from '@vue/composition-api'
import { fetchLesson } from '@/services/strapi'

export default {
  components: {
    FHAudio,
    FHButton,
    FHHeading,
    ContentLoader
  },

  setup() {
    let shouldAutoplay = false
    const audiobookLesson = ref(null)
    const description = ref('')
    const loading = ref(true)
    const currentAudio = ref(null)
    const audioList = ref(null)
    const audioPlayer = ref(null)
    const currentAudioIndex = ref(null)
    const setCurrentAudio = (index, autoPlay = true) => {
      if (!audioList.value.length) throw Error('Audio list is empty.')
      currentAudioIndex.value = index
      currentAudio.value = audioList.value[index]
      if (autoPlay) {
        shouldAutoplay = true
      }
    }
    const registerPlayer = player => {
      audioPlayer.value = player

      if (shouldAutoplay) {
        audioPlayer.value.play()
      }
    }

    onMounted(async () => {
      audiobookLesson.value = await fetchLesson('synopsis-audiobok')
      description.value = audiobookLesson.value.description || ''
      const audiosOnly = audiobookLesson.value.learningElementSection.filter(le => le.__component === 'learning-element.audio-element')
      audioList.value = audiosOnly.map(ac => ac.audio_type)
      setCurrentAudio(0, false)
      loading.value = false
    })

    return {
      audioList,
      currentAudio,
      currentAudioIndex,
      description,
      loading,
      setCurrentAudio,
      registerPlayer,
    }
  }
}
</script>

<style lang="scss">
.v-lydbok {
  padding: 3rem 2rem;
}

.v-lydbok__audio-wrapper {
  margin-top: 3rem;
}

.v-lydbok__chapters-wrapper {
  margin-top: 2rem;
  margin: 2rem -1rem 0;
}

.v-lydbok__button {
  margin: 1rem;
  background: $audio-chapter-bg;
  border: 1px solid $color-grey-blue;

  &:hover {
    background: darken($audio-chapter-bg, 10%);
  }

  &--active {
    background: $color-brand;
    color: $color-white;
  }
}

.c-custom-audio-player__button {
  &:hover {
    [class*="icon-"]::before {
      color: rgba($color-brand, 0.85);
    }
  }

  i::before {
    transition: color 0.25s ease-in-out;
    color: $color-brand;
  }

  &::after {
    color: $color-black;
  }
}

.c-custom-audio-player {
  background: $audio-chapter-bg;
  border-radius: 2rem;  

  .vue-slider-dot-handle,
  .vue-slider-process,
  .vue-slider-dot-tooltip-inner  {
    background: $color-brand;
  }
}

.c-custom-audio-player__time {
  color: $color-black;
}
</style>
